import Vue from "vue";
import Router from "vue-router";
import store from "@/core/services/store";

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => {
                        if (store.state.auth.user.type == 1) {
                            return import("@/view/pages/realstate/user/User")
                        } else {
                            return import("@/view/pages/realstate/dashboard/Dashboard")
                        }
                    }
                },
                {
                    path: "/favourites",
                    name: "favourites",
                    component: () => {
                        if (store.state.auth.user.type == 1) {
                            return import("@/view/pages/realstate/favourites/Favourites")
                        } else {
                            return import("@/view/pages/realstate/dashboard/Dashboard")
                        }
                    }
                },
                {
                    path: "/payments",
                    name: "payments",
                    component: () => {
                        if (store.state.auth.user.type == 1) {
                            return import("@/view/pages/realstate/payments/Payments")
                        } else {
                            return import("@/view/pages/realstate/dashboard/Dashboard")
                        }
                    }
                },
                {
                    path: "/submit-article",
                    name: "submit-article",
                    component: () => {
                        if (store.state.auth.user.type == 1) {
                            return import("@/view/pages/realstate/article-form/ArticleForm")
                        } else {
                            return import("@/view/pages/realstate/dashboard/Dashboard")
                        }
                    }
                },
                {
                    path: "/preferences",
                    name: "preferences",
                    component: () => {
                        if (store.state.auth.user.type == 1) {
                            return import("@/view/pages/realstate/preferences/Preferences")
                        } else {
                            return import("@/view/pages/realstate/dashboard/Dashboard")
                        }
                    }
                },
                {
                    path: "/arbitration",
                    name: "arbitration",
                    component: () => {
                        if (store.state.auth.user.type == 2) {
                            return import("@/view/pages/realstate/arbitration/Arbitration")
                        } else {
                            return import("@/view/pages/realstate/user/User")
                        }
                    }
                },
                {
                    path: "/mediation",
                    name: "mediation",
                    component: () => {
                        if (store.state.auth.user.type == 2) {
                            return import("@/view/pages/realstate/mediation/Mediation")
                        } else {
                            return import("@/view/pages/realstate/user/User")
                        }
                    }
                },
                {
                    path: "/bookings",
                    name: "bookings",
                    component: () => {
                        if (store.state.auth.user.type == 2) {
                            return import("@/view/pages/realstate/bookings/Bookings")
                        } else {
                            return import("@/view/pages/realstate/user/User")
                        }
                    }
                },
                {
                    path: "/client-payments",
                    name: "client-payments",
                    component: () => {
                        if (store.state.auth.user.type == 2) {
                            return import("@/view/pages/realstate/payments/ClientPayments")
                        } else {
                            return import("@/view/pages/realstate/user/User")
                        }
                    }
                }
            ]
        },
        {
            path: "/error",
            name: "error",
            component: () => import("@/view/pages/error/Error.vue"),
            children: [
                {
                    path: "error-1",
                    name: "error-1",
                    component: () => import("@/view/pages/error/Error-1.vue")
                },
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/view/pages/error/Error-2.vue")
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/view/pages/error/Error-3.vue")
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/view/pages/error/Error-4.vue")
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/view/pages/error/Error-5.vue")
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/view/pages/error/Error-6.vue")
                }
            ]
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/Login")
                },
                {
                    name: "client-login",
                    path: "/client-login",
                    component: () => import("@/view/pages/auth/ClientLogin")
                },
                {
                    name: "forgot-password",
                    path: "/forgot-password",
                    component: () => import("@/view/pages/auth/ForgotPassword")
                },
                {
                    name: "client-forgot-password",
                    path: "/client-forgot-password",
                    component: () => import("@/view/pages/auth/ClientForgotPassword")
                },
                {
                    name: "reset-password",
                    path: "/reset-password/:id",
                    component: () => import("@/view/pages/auth/ResetPassword")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () => import("@/view/pages/realstate/Vuetify.vue"),
                    children: [
                        {
                            name: "panelist",
                            path: "/panelist",
                            component: () => import("@/view/pages/auth/PanelistRegister")
                        },
                        {
                            name: "client",
                            path: "/client",
                            component: () => import("@/view/pages/auth/Register")
                        }
                    ]
                }
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue")
        }
    ]
});
