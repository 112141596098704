import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import snackbar from "./snackbar.module";
import confirmation from "./confirmation.module";
import uploads from "./uploads.module";
import dashboard from "./dashboard.module";
import users from "./users.module";
import cases from "./cases.module";
import bookings from "./bookings.module";

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        appUrl: ''
    },
    modules: {
        auth,
        htmlClass,
        config,
        uploads,
        breadcrumbs,
        snackbar,
        confirmation,
        dashboard,
        users,
        cases,
        bookings,
    }
});
