import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const BOOKINGS_LIST = "bookings_list";
export const BOOKINGS_SIMPLE_LIST = "bookings_simple_list";
export const CREATE_BOOKING = "create_booking";
export const UPDATE_BOOKING = "update_booking";
export const UPDATE_BOOKING_STATUS = "UPDATE_BOOKING_STATUS";
export const DELETE_BOOKING = "delete_booking";
export const GET_BOOKING_DETAILS = "get_booking_details";
export const CLEAR_BOOKING_ERRORS = "clearBookingErrors";

// mutation types
export const SET_BOOKING_ERROR = "setBookingError";
export const REMOVE_BOOKING_ERRORS = "removeBookingErrors";

const state = {
    errors: null
};


const actions = {
    [BOOKINGS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/bookings?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_BOOKING_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_BOOKING_ERROR, response.errors);
                });
        });
    },
    [BOOKINGS_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/bookingssimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
    [GET_BOOKING_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getbookingdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_BOOKING_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_BOOKING_ERROR, response.errors);
                });
        });
    },
    [CREATE_BOOKING](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createbooking", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BOOKING_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BOOKING_ERROR, response.errors);
                });
        });
    },
    [UPDATE_BOOKING](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatebooking", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BOOKING_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BOOKING_ERROR, response.errors);
                });
        });
    },
    [UPDATE_BOOKING_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatebookingstatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BOOKING_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BOOKING_ERROR, response.errors);
                });
        });
    },
    [DELETE_BOOKING](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletebooking", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BOOKING_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BOOKING_ERROR, response.errors);
                });
        });
    },
    [CLEAR_BOOKING_ERRORS](context) {
        context.commit(REMOVE_BOOKING_ERRORS);
    },
};

const mutations = {
    [SET_BOOKING_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_BOOKING_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
