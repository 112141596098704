import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const CASES_LIST = "cases_list";
export const CASES_SIMPLE_LIST = "cases_simple_list";
export const CREATE_CASE = "create_case";
export const UPDATE_CASE = "update_case";
export const UPDATE_CASE_STATUS = "UPDATE_CASE_STATUS";
export const DELETE_CASE = "delete_case";
export const GET_CASE_DETAILS = "get_case_details";
export const CLEAR_CASE_ERRORS = "clearCaseErrors";

// mutation types
export const SET_CASE_ERROR = "setCaseError";
export const REMOVE_CASE_ERRORS = "removeCaseErrors";

const state = {
    errors: null,
    caseClaimants: [],
    caseRespondents: []
};


const actions = {
    [CASES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/cases?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_CASE_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_CASE_ERROR, response.errors);
                });
        });
    },
    [CASES_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/casessimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
    [GET_CASE_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getcasedetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_CASE_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_CASE_ERROR, response.errors);
                });
        });
    },
    [CREATE_CASE](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createcase", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CASE_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CASE_ERROR, response.errors);
                });
        });
    },
    [UPDATE_CASE](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatecase", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CASE_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CASE_ERROR, response.errors);
                });
        });
    },
    [UPDATE_CASE_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatecasestatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CASE_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CASE_ERROR, response.errors);
                });
        });
    },
    [DELETE_CASE](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletecase", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CASE_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CASE_ERROR, response.errors);
                });
        });
    },
    [CLEAR_CASE_ERRORS](context) {
        context.commit(REMOVE_CASE_ERRORS);
    },
};

const mutations = {
    [SET_CASE_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_CASE_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
